import { faInstagram, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { AppsNavigationItem } from "../dto/NavigationItem";
import { ParticipantCreditsPackagesDTO } from "../dto/ParticipantCreditsPackagesDTO";
import { WinnerDTO } from "../dto/WinnerDTO";
import { getGenerateRandomInt } from "../helpers/NumberHelper";
import { NavigationItemTypeEnum } from "../helpers/NavigationItemTypeEnum";
import { RoutesEnum } from "../routes/RoutesEnum";
import { faCoins, faDice, faImage, faListNumeric, faRandom, faSearch, faX } from "@fortawesome/free-solid-svg-icons";

export const version = "1.0.7";
export const isTestComponentAlwaysDisplay = (process.env.NODE_ENV === 'development') && false;
export const isMaintenance = (process.env.NODE_ENV === 'development') ? false : false;
const isSimulateData = (process.env.NODE_ENV === 'development') ? false : false;
export const isDevelopmentReactStrictMode = false;
export const allowPwaOnLocalhost = false;

export const minParticipantsCount: number = 3;
export const maxParticipantsCount: number = 500000;
export const paginationSize = 15;

/*
used in fetching campaign data (comments, etc.) via API
*/
export const apiNoFetchExceptionDelayRequestsMs = 1000;
export const apiNoFetchMaxRetry = 5;
/*
used in fetching general data via API
*/
export const defaultWaitBetweenRequestsMs = 2000;
export const defaultMaxRetry = 20;

export const getGenerateSimulateDataParticipants = (count: number) => {
    const participants: WinnerDTO[] = [];
    for (let i = 0; i < count; i++) {
        participants.push({
            Username: `User${"test"}`,
            UsernameDisplay: `@User${"test"}`,
            ProfileImage: `https://randomuser.me/api/portraits/men/${getGenerateRandomInt(1, 99)}.jpg`,
            Comment: `This is a comment number ${i + 1}`,
            CommentLink: `https://example.com/comment/${i + 1}`,
            CommentLikeCount: getGenerateRandomInt(0, 999999),
            ProfileLink: `https://example.com/profile/${i + 1}`,
            IsValid: true,
            IsSubstitute: false
        });
    }
    return participants;
}
export const simulateData = {
    isSimulateData: isSimulateData,
    Participants: getGenerateSimulateDataParticipants(5000)
};

export const participantCreditPackages: ParticipantCreditsPackagesDTO[] = [
    {
        ParticipantCredits: 500,
        Price: 2.90
    },
    {
        ParticipantCredits: 3000,
        Price: 4.90
    },
    {
        ParticipantCredits: 10000,
        Price: 7.90
    },
    {
        ParticipantCredits: 30000,
        Price: 11.90
    },
    {
        ParticipantCredits: 100000,
        Price: 19.90
    },
    {
        ParticipantCredits: 500000,
        Price: 39.90
    },
];

export const navigationAppsItems: AppsNavigationItem[] = [
    {
        Header: {
            Name: "Giveaways / Promotions",
            Items: [
                {
                    Name: "Instagram Giveaway",
                    Icon: faInstagram,
                    NavigationItemType: NavigationItemTypeEnum.App,
                    TextColor: "text-instagram",
                    Route: RoutesEnum.APP_INSTAGRAM_URL,
                },
                {
                    Name: "TikTok Giveaway",
                    Icon: faTiktok,
                    NavigationItemType: NavigationItemTypeEnum.App,
                    TextColor: "text-tiktok",
                    Route: RoutesEnum.APP_TIKTOK_URL,
                },
                {
                    Name: "Youtube Giveaway",
                    Icon: faYoutube,
                    NavigationItemType: NavigationItemTypeEnum.App,
                    TextColor: "text-youtube",
                    Route: RoutesEnum.APP_YOUTUBE_URL,
                },
                {
                    Name: "Twitter / X Giveaway",
                    Icon: faX,
                    NavigationItemType: NavigationItemTypeEnum.App,
                    TextColor: "text-twitter",
                    Route: RoutesEnum.APP_TWITTER_URL,
                },
                {
                    Name: "Random Name Winner Giveaway",
                    Icon: faListNumeric,
                    NavigationItemType: NavigationItemTypeEnum.App,
                    TextColor: "text-randomNameWinner",
                    Route: RoutesEnum.APP_RANDOM_NAME_WINNERS,
                },
            ]
        }
    },
    {
        Header: {
            Name: "Tools",
            Items: [
                {
                    Name: "Search Giveaway by Code",
                    Icon: faSearch,
                    NavigationItemType: NavigationItemTypeEnum.App,
                    TextColor: "text-secondary",
                    Route: RoutesEnum.CAMPAIGN_SEARCH_BY_CODE,
                },
                {
                    Name: "Create Giveaway Image",
                    Icon: faImage,
                    NavigationItemType: NavigationItemTypeEnum.App,
                    TextColor: "text-numbers",
                    Route: RoutesEnum.APP_CREATE_GIVEAWAY_IMAGE,
                },
            ]
        }
    },
    {
        Header: {
            Name: "Random",
            Items: [
                {
                    Name: "Roll Dice",
                    Icon: faDice,
                    NavigationItemType: NavigationItemTypeEnum.App,
                    TextColor: "text-secondary",
                    Route: RoutesEnum.APP_ROLL_DICE,
                },
                {
                    Name: "Random Numbers",
                    Icon: faRandom,
                    NavigationItemType: NavigationItemTypeEnum.App,
                    TextColor: "text-numbers",
                    Route: RoutesEnum.APP_RANDOM_NUMBERS,
                },
                {
                    Name: "Flip a Coin",
                    Icon: faCoins,
                    NavigationItemType: NavigationItemTypeEnum.App,
                    TextColor: "text-coins",
                    Route: RoutesEnum.APP_FLIP_COIN,
                },
            ]
        }
    }
];