import * as Sentry from '@sentry/react';

type LogLevel = 'error';

class LoggerService {
    private static instance: LoggerService;

    private constructor() {
        Sentry.init({
            dsn: "https://7a1efd0838c891c170f9a0946a12401b@o4507594091659264.ingest.de.sentry.io/4507596703662160",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }

    public static getInstance(): LoggerService {
        if (!LoggerService.instance) {
            LoggerService.instance = new LoggerService();
        }
        return LoggerService.instance;
    }

    private log(level: LogLevel, error?: any) {
        switch (level) {
            case 'error':
                Sentry.captureException(error);
                break;
        }

    }

    error(error?: any) {
        this.log('error', error);
    }
}

const loggerService = LoggerService.getInstance();

export default loggerService;